.bb-article-block {
  margin-bottom: 15px;
}

.bb-article-block a {
  cursor: pointer;
  text-decoration: none;
}

.bb-article-image {
  width: 100%;
  margin-bottom: 0;
}

.bb-article-content {
  margin: 0 0 0 0;
  padding: 15px;

  border: solid;
  border-width: 0 1px 1px 1px;
  border-color: #e7e7e7;
  
  overflow: hidden;
}

.bb-article-content.full-border {
  border-width: 1px 1px 1px 1px;
}

.bb-article-content h3 {
  margin-top: 0;
}

.bb-article-content p {
  margin-bottom: 5px;
}

.bb-article-content ul {
  padding: 0;  
  list-style-type: none;
}

.bb-article-content ul li {
  margin: 0 0 10px;
}

.bb-article-content ul li a {
  color: black;
}
.bb-article-content ul li a:hover {
    color: #67b7e4;
    text-decoration: none;
}

.bb-article-content date {
  
}

.bb-article-detail-block a {
  cursor: pointer;
  text-decoration: none;
}

.article-title,
.blog-title,
.article-date {
  color: black;
}

.blog-title {
  //font-weight: bold;
  font-style: italic;
}

.article-text {
  margin-top: 15px;
  margin-bottom: 15px;
  overflow: hidden;
}

.plus-button {
  display: inline-block;
  cursor: pointer;
  margin-top: 7px;
  background: none;
  border: none;
}

.plus-button:focus {
  outline: none;
}

.article-trending,
.article-keyword,
.article-follow {
  display: inline-block;
  color: white;
  //margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 6px;
  vertical-align: top;
}

.article-trending {
  background-color: #ef615b;
  cursor: default;
}

.article-trending img {
  vertical-align: bottom;
}

.article-keyword {
  background-color: #5bc0de;
}

.article-follow {
  background-color: #9B9B9B;
}