.signUpButton span,
.signUpButton div {
  display: block;
  font-weight: bold;
  text-align: left;
  line-height: 100%;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  
}
.sign-up-btn-join {
  color: black;
  font-size: 2.6667em;
   display: inline-block;
}
.sign-up-btn-number {
  color: white;
  font-size: 2.6667em;
   display: inline-block;
}
.sign-up-btn-insiders {
  color: black;
  font-size: 2.6667em;
  text-align:left;
}
.sign-up-btn-text {
  color: white;
  font-size: 12px;
}
 .hline {
    width:30%;
    height:1px;
    background: #000;
    width: 20%;margin-left: 6%;margin-right: auto;margin-top:2%;
  }
 .inputbox_cta input::-moz-placeholder {
    opacity: 1;
    font-size: 14pt;
  }
  .inputbox_cta label {
    font-size:12px;
    white-space: normal;
  }
  .inputbox_cta input:-ms-input-placeholder {
    opacity: 1;
    font-size: 14pt;
  }
  
  .inputbox_cta input::-webkit-input-placeholder {
    opacity: 1;
    font-size: 14pt;
  }
  
  .inputbox_cta {
    padding-right: 5px;
    overflow: hidden;
  }
  
  .first-line {
    padding-left: 5.5%;
    margin-bottom: -10px;
    font-family: 'Arial', Helvetica, Arial, sans-serif;
    font-weight: bold;
	color:black;
	text-align:left;
	padding-right: 5%;
	padding-top: 15px;
  }
 .second-line {
   padding-left: 5.5%;
   color:black;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
   text-align:left;
   padding-right: 5%;
   font-size: 2.0625em;
  }
  .third-line {
   padding-left: 5.5%;
   color: white;
   font-size: 1.35em;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
   text-align:left;
   padding-right: 5%;
  }
  
  .last-line {
    padding-left: 5.5%;
   color:black;
   text-align:left;
   font-size: 12px;
   text-align:left;
   white-space: normal;
   padding-right: calc(28px - 10%);
   padding-bottom: calc(28px - 20%);
  }
  .last-line b {
   color:black;
  }
.slabtexted .slabtext {
  display:-moz-inline-box;
  display:inline-block;
  white-space:nowrap
}
.slabtextinactive .slabtext{
  display:inline;
  white-space:normal;
  font-size:1em !important;
  letter-spacing:inherit !important;
  word-spacing:inherit !important;
  *letter-spacing:0 !important;
  *word-spacing:0 !important;
}
.slabtextdone .slabtext {
  display:block;line-height:0.9;
}


/* New design styles */

.modal-dialog .form-group {
  padding: 0 .5em 0 .5em;
  background: white;
}

.modal-dialog .validation {
    padding: 0 .5em 0 .5em;
}

.modal-open {
  // TODO Fixing modal windows scrolling - should be commented out
  //overflow: auto;
}

.modal {
  //position: absolute;
  
  // TODO Fixing modal windows scrolling - should be commented out
  //top: 5px;
  //bottom: auto;
  
  /*margin-left: 5px;
  margin-right: 5px;*/
}

// Fix shifting body 17px left after opening any Bootstrap modal window   
body.modal-open {
  //padding-right: 0px !important;  // Bad fix. JS should be used
  
  // TODO Fixing modal windows scrolling - should be commented out
  //overflow-y: auto;
}

.bb-modal-content {
  border-radius: 0;
  background-color: white;

  position:fixed;
  top:50%;
  left:50%;  
  
  margin-left: -150px;
}

.modal-header.empty-modal-header {
  padding: 0;
  border: none;
}
.modal-header.empty-modal-header .close {
  margin: 2px;
}

.modal-header.empty-modal-header + .modal-body {
  margin-top: 0;
  padding-top: 0;
}

.bb-modal-header {
    background-color: #63b6e6;
    color: white;
    margin-bottom: 50px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.bb-modal-header:after {
    display: block;
    content: '';
    position: absolute;
    margin-top: 30px;
    left: 50%;
    margin-left: -35px;
    width: 0;
    height: 0;
    border-top: solid 35px #63b6e6;
    border-left: solid 35px transparent;
    border-right: solid 35px transparent;
}

button.close {
  margin: 5px;
  text-shadow: none;
  /*color: white;*/
  /*opacity: 1;*/
}

.modal-content {
    border-radius: 0px;
    
    /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
    width:inherit;
    height:inherit;
    /* To center horizontally */
}


.text-center {
    text-align: center;
}
.modal-title {
    margin: 0;
    line-height: 1.42857143;
}

/* Flat Theme */

.form-control {
    border-color: #46b8da;
}

.orange {
  background-color: #e2ad66;
}

.btn.flat {
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  /*border-width: 1px;*/
  border: none;
  border-width: 0px;
}

.btn.btn-default.flat {
  border: solid 1px #46b8da;
  color: black;
}


.form-control.flat {
  border-radius: 0;
  box-shadow: none;
  border-color: #46b8da;
}

.btn.orange.flat:focus,
.btn.orange.flat.focus {
    background-color: #b37522;
}
.btn.orange.flat:hover{
    background-color: #b37522;
}

.text-aqua {
    color: #00c0ef !important;
}

.text-muted {
    color: #777;
}

.edit-form-button {
  /*margin-bottom: 15px;*/
}

fieldset {
  padding-top: 10px;
}

.fieldset-button-block {
  margin-top: -10px;
  margin-bottom: 5px;
}

.modal-dialog input {
  background-color: #eeeeee;
  border: none;
  border-radius: 0px;
}

.modal-dialog label {
  font-weight: normal;
  color: #777;
}

.modal-dialog input[type=submit] {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #eea236;
}

.modal-dialog input[type=submit]:hover {
  background-color: #ec971f;
}

.modal-dialog select,
.modal-dialog select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  
  outline:0;
  border-radius:0;
  -webkit-border-radius:0;
  
  background:url("data:image/svg+xml;utf8,<svg%20xmlns=%27http://www.w3.org/2000/svg%27%20width=%2750px%27%20height=%2750px%27><rect%20fill=%27%235bc0de%27%20x=%270%27%20y=%270%27%20width=%2750%27%20height=%2750%27/><polyline%20fill=%27%23ffffff%27%20points=%2740,17 25,33 10,17%27/></svg>");
  background-repeat:no-repeat;
  background-position: right 0px top 0px;
  background-size: 34px 34px;
  
  -webkit-transition:0.3s ease all;
     -moz-transition:0.3s ease all;
      -ms-transition:0.3s ease all;
       -o-transition:0.3s ease all;
          transition:0.3s ease all;
          
  border: none 0px #46b8da;
  background-color:#eeeeee;
}

.modal-dialog select:focus, 
.modal-dialog select:active,
.modal-dialog select.form-control:focus, 
.modal-dialog select.form-control:active {
  outline:0;
}

.modal-dialog select option {
  background-color: white;
  color: black;
}

select:invalid,
select:invalid.form-control { 
  color: white; 
}

.fa.social-button {
  font-size: 38px;
}

@media (min-width: 838px) {
  .vertical-separator
  {
    /*
    margin-left: 50px;
    float: left;
    width: 1px !important;
    height: 252px;
    */
    border-right: 1px solid #acacac;
    margin-bottom: 15px;
  }
  
  .vertical-separator:after {
    content: " or ";
    background: white;
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -15px;
    margin-left: -15px;
    z-index: 3;
  }
  
  .loginSeparator img
  {
    margin-left: -22px;
    margin-top: 80px;
  }
  
  .horizontal-separator-container,
  .horizontal-separator-container .form-group {
    height: 0px;
    margin: 0px;
  }
}

@media (max-width: 837px) {
  .horizontal-separator
  {
    border-bottom: 1px solid #acacac;
    margin-top: 10px;
    margin-bottom: 15px;
    /*margin-bottom: 29px;
    padding-bottom: 10px;*/
  }
  
  .horizontal-separator:after {
    content: " or ";
    background: white;
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    position: absolute;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
    z-index: 3;
  }
}

.keyword {
  padding-left: 1px; 
  padding-right: 1px;
  margin-bottom: 1px;
}

.keyword .btn{
  text-align: left;
  background-color: #67b7e4;
}

.keyword .btn:focus,
.keyword .btn:active {
   outline: none !important;
}

/* Breadcrumbs */

.btn-breadcrumb {
  position: relative;
  min-height: 1px;
  float: left;
  width: 100%;
  
  border-top: solid 1px white;
  border-bottom: solid 1px white;  
  
  margin-bottom: 30px;
}

/** The Magic **/
.btn-breadcrumb .btn:not(:last-child):after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 10px solid white;
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: 100%;
  z-index: 3;
}
.btn-breadcrumb .btn:not(:last-child):before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 10px solid rgb(173, 173, 173);
  position: absolute;
  top: 50%;
  margin-top: -17px;
  margin-left: 1px;
  left: 100%;
  z-index: 3;
}

/** The Spacing **/
.btn-breadcrumb .btn {
  padding:6px 12px 6px 24px;
}
.btn-breadcrumb .btn:first-child {
  padding:6px 6px 6px 10px;
}
.btn-breadcrumb .btn:last-child {
  padding:6px 18px 6px 24px;
}

.btn-breadcrumb .btn-5 {
  width: 20%
}

/** Colors */
.btn-breadcrumb .btn.btn-header {
  color: #ffffff;
  /*background-color: #63b6e6;*/
  background-color: transparent;
  
}
.btn-breadcrumb .btn.btn-header:hover {
  color: #269abc;
  /*background-color: #63b6e6;*/
}

/** Info button #5bc0de -> #63b6e6**/
.btn-breadcrumb .btn.btn-header {
  /*background: #63b6e6;*/
  /*border-color: #5bc0de;*/
  
  /*border-color: white;*/
}
.btn-breadcrumb .btn.btn-header:not(:last-child):after {
  border-left: 10px solid #63b6e6;
}
.btn-breadcrumb .btn.btn-header:not(:last-child):before {
  border-left: 10px solid white;
}
/*
.btn-breadcrumb .btn.btn-header:hover:not(:last-child):after {
  border-left: 10px solid #63b6e6;
}
.btn-breadcrumb .btn.btn-header:hover:not(:last-child):before {
  border-left: 10px solid #269abc;
}
*/

.btn-breadcrumb .btn:active,
.btn-breadcrumb .btn.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}


/* Breadcrumbs-Tabs (2) */

ul.nav-wizard {
  background-color: #63b6e6;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  *zoom: 1;
  position: relative;
  overflow: hidden;
  
  margin-bottom: 30px;
  float: left;
  width: 100%;
}
ul.nav-wizard:before {
  display: block;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 46px;
  height: 47px;
  border-top: 1px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
  z-index: 11;
  content: " ";
}
ul.nav-wizard:after {
  display: block;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 138px;
  height: 47px;
  border-top: 1px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
  z-index: 11;
  content: " ";
}
ul.nav-wizard li {
  position: relative;
  float: left;
  height: 46px;
  display: inline-block;
  text-align: middle;
  padding: 0 0 0 10px;
  margin: 0;
  font-size: 14px;
}
ul.nav-wizard li a {
  color: white;
  padding: 0;
  
  text-align: center;
  
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

ul.nav-wizard li a i,
ul.nav-wizard li a img,
ul.nav-wizard li a span {
  vertical-align: middle
}

ul.nav-wizard li a:hover {
  background-color: transparent;
}
ul.nav-wizard li:before {
  position: absolute;
  display: block;
  border: 24px solid transparent;
  border-left: 16px solid white;
  border-right: 0;
  top: -1px;
  z-index: 10;
  content: '';
  right: -16px;
}
ul.nav-wizard li:after {
  position: absolute;
  display: block;
  border: 24px solid transparent;
  border-left: 16px solid #63b6e6;
  border-right: 0;
  top: -1px;
  z-index: 10;
  content: '';
  right: -15px;
}
ul.nav-wizard li.active {
  color: white;
  background: #ec971f;
}
ul.nav-wizard li.active:after {
  border-left: 16px solid #ec971f;
}
ul.nav-wizard li.active a,
ul.nav-wizard li.active a:active,
ul.nav-wizard li.active a:visited,
ul.nav-wizard li.active a:focus {
  color: white;
  background: #ec971f;
}
ul.nav-wizard .active ~ li {
  /*
  color: #999999;
  background: #ededed;
  */
  color: #ededed;
}
ul.nav-wizard .active ~ li:after {
  border-left: 16px solid #63b6e6;
}
ul.nav-wizard .active ~ li a,
ul.nav-wizard .active ~ li a:active,
ul.nav-wizard .active ~ li a:visited,
ul.nav-wizard .active ~ li a:focus {
  /*
  color: #999999;
  background: #ededed;
  */
  color: #ededed;
}


/*
ul.nav-wizard .active a i,
ul.nav-wizard .active ~ li a i {
  display: none !important;
  font-size: 18px;
}

ul.nav-wizard img {
  display: none !important;
}
ul.nav-wizard .active a img,
ul.nav-wizard .active ~ li a img {
  display: inline !important;
}
*/


ul.nav-wizard a i {
  display: none !important;
}
ul.nav-wizard li.submitted a i {
  display: inline !important;
  font-size: 18px;
}

ul.nav-wizard a img {
  display: inline !important;
}
ul.nav-wizard li.submitted a img {
  display: none !important;
}



ul.nav-wizard.nav-wizard-backnav li:hover {
  color: white;
  background: #f6fbfd;
}
ul.nav-wizard.nav-wizard-backnav li:hover:after {
  border-left: 16px solid #f6fbfd;
}
ul.nav-wizard.nav-wizard-backnav li:hover a,
ul.nav-wizard.nav-wizard-backnav li:hover a:active,
ul.nav-wizard.nav-wizard-backnav li:hover a:visited,
ul.nav-wizard.nav-wizard-backnav li:hover a:focus {
  color: white;
  background: #f6fbfd;
}
ul.nav-wizard.nav-wizard-backnav .active ~ li {
  /*
  color: #999999;
  background: #ededed;
  */
  color: #ededed;
}
ul.nav-wizard.nav-wizard-backnav .active ~ li:after {
  border-left: 16px solid #ededed;
}
ul.nav-wizard.nav-wizard-backnav .active ~ li a,
ul.nav-wizard.nav-wizard-backnav .active ~ li a:active,
ul.nav-wizard.nav-wizard-backnav .active ~ li a:visited,
ul.nav-wizard.nav-wizard-backnav .active ~ li a:focus {
  /*
  color: #999999;
  background: #ededed;
  */
  color: #ededed;
}

@media (max-width: 838px) {
  ul.nav-wizard li a .symbol {
    display: block;
    width: 100%;
  }
}
 
.one-of-3 {
  width: 33.33%
}

.one-of-4 {
  width: 25%
}

.one-of-5 {
  width: 20%
}

.one-of-6 {
  width: 16.66%
}

a:link.forgotPassword {
  color: #67b7e4;
}
a:visited.forgotPassword {
  color: #67b7e4;
}
a:hover.forgotPassword {
  color: #67b7e4;
}
a:active.forgotPassword {
  color: #67b7e4;
}

.rememberMe {
  padding: 0;
}
.forgotPassword {
  padding: 0;
  text-align: right;
}

#newRegistrationForm h1.modal-title {
  font-weight: bold;
}

#newRegistrationForm h4.modal-title {
  font-size: 12px;
}

.site-banner {
  background: white;
  border-radius: 16px;
  padding: 8px;
  margin: 16px auto;
  text-align: left;

  .site-title-block {
    height: 50px;
  }
  
  .site-title-block a,
  .site-title-block a:hover,
  .site-title-block a:focus
   {
    text-decoration: none;
    cursor: pointer;
  }
  
  .site-title {
    font-weight: bold;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #9B9B9B;
    font-size: 25px;
  }
  
  .site-subtitle {
    font-weight: normal;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #9B9B9B;
    font-size: 12px;
  }
}

@media (min-width: 561px) { 
  .site-banner {
    width: 80%;
  }
   img.site-logo {
    max-width: 76px;
    max-height: 76px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
@media (max-width: 560px) {
  #newRegistrationForm h1.modal-title {
    font-size: 22px;
  }
   img.site-logo {
    max-width: 40px;
    max-height: 50px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
 }
@media (max-width: 561px) {  
  #newRegistrationForm h1.modal-title {
    font-size: 22px;
  }
}

/* Bigger Smartphones */
@media (max-width: 480px) {
  .modal-dialog {
    width: 420px;
    margin-left: auto;
    margin-right: auto;
  }
  .bb-modal-header {
    padding-left: 10px;
    padding-right: 10px;
  }
  .site-banner .site-title {
    font-size: 20px;
  }
}


/* iPhone 6 Plus */
@media (max-width: 420px) {
  .modal-dialog {
    width: 360px;
    margin-left: auto;
    margin-right: auto;
  }
  .bb-modal-header {
    padding-left: 10px;
    padding-right: 10px;
  }
  .site-banner .site-title {
    font-size: 18px;
  }
}

/* iPhone 6 */
@media (max-width: 375px) {
  .modal-dialog {
    width: 310px;
    margin-left: auto;
    margin-right: auto;
  }
  .bb-modal-header {
    padding-left: 10px;
    padding-right: 10px;
  }
  .site-banner .site-title {
    font-size: 18px;
  }
}

/* iPhone 5 */
@media (max-width: 320px) {
  .modal-dialog {
    width: 260px;
    margin-left: auto;
    margin-right: auto;
  }
  .bb-modal-header {
    padding-left: 10px;
    padding-right: 10px;
  }
  .site-banner .site-title {
    font-size: 18px;
  }
  #newRegistrationForm h1.modal-title {
    font-size: 18px;
  }
}


.complete-registration-form .validation,
.modal-dialog .validation {
  font-size: 20px;
  color: red;
  text-align: center;
}

.input-error input,
input.input-error,
.input-error select,
select.input-error,
.input-error .select2-selection,
.select2-selection.input-error {
  border: solid 2px red !important;
}

.input-error p {
  color: red;
}

.required input, input.required {
   padding-right: 25px;
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='72' height='69'%3E%3Cpath fill='%23a0a0a0' d='m44.29300308227539 0l-3.036998987197876 28.29399871826172 28.516998291015625-7.9990010261535645 2.074005126953125 16.073001861572266 -26.66500473022461 2.1479990482330322 17.479999542236328 23.035003662109375 -14.739997863769531 7.924994945526123 -12.295001983642578-25.03499984741211 -11.036998748779297 25.03499984741211 -15.183998107910156-7.924994945526123 17.109996795654297-23.035003662109375 -26.516002655029297-2.1479990482330322 2.5920028686523438-16.073001861572266 27.85000228881836 7.9990010261535645 -3.0370030403137207-28.29399871826172h16.88800048828125l0 0 0 0z'/%3E%3C/svg%3E");
   background-size: 7px;
   background-repeat: no-repeat;
   background-position: right 4px top 4px;
}

input.required.disabled-form-field {
   background: none !important;
}

/*****************************************************/

div.oauth-connected label.text-muted {
  display: none;
}
div.oauth-not-connected {
  & .oAuthLogin i.fa-check-circle-o,
  & label.text-aqua {
    display: none;
  }
}

span.avoidwrap { 
  display:inline-block; 
}

.loading-indicator
{
  display: none;
}


.site-footer.background-gray {
  min-height: 100px;
  padding: 35px 0 20px 0;
  /*margin-bottom: 1em;*/
  /*background-color: #F2F2F2;*/
}

@media (min-width: 1101px) {
  .site-footer.background-gray {
    padding-top: 40px;
  }
}

.site-footer a {
  color: #333333;
}
.site-footer a.highlighted-link {
  color: black;
  text-decoration: underline;
}
.site-footer .btn-info {
  color: white;
}

.footer-column-header {
  font-weight: bold;
  
}

.site-footer ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 20px;
}
  
/*
@media (max-width: 561px) {
  .footer-column-header {
    text-align: center;
  }
}
*/

@media (max-width: 561px) {
  .navbar-fixed-top .navbar-collapse {
    max-height: 100%;
  }
  .stuck .navbar-collapse {
    max-height: 100%;
  }
}
@media screen and (min-width: 838px) and (max-width: 950px) {
.sign-up-btn-join {
  font-size: 1.6667em;
}
.sign-up-btn-number {
  font-size: 1.6667em;
}
.sign-up-btn-insiders {
  font-size: 1.6667em;
 }
.sign-up-btn-text {
  color: white;
  font-size: 12px;
}
 .second-line {
   font-size: 1.0625em;
  }
  .first-line {
    
    margin-bottom: -2px;
    }
    
    .third-line {
    font-size: 1.00em;
    }
}